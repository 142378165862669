<script setup lang="ts">
const props = defineProps({
    blok: {
        type: Object,
        default: () => ({}),
    },
    id: {
        type: String,
        default: '',
    },
});

function closeMenu() {
    const menu = document.getElementById(`mega-menu-${props.id}`);
    if (menu) {
        menu.classList.remove('block');
        menu.classList.add('hidden');
    }
}
</script>

<template>
    <li>
        <button
            :id="`mega-menu-button-${id}`"
            :data-dropdown-toggle="`mega-menu-${id}`"
            class="flex justify-between items-center py-2 pr-4 pl-3 w-full font-medium text-gray-700 border-b border-gray-100 lg:w-auto hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-primary-600 lg:p-0 dark:text-gray-400 lg:dark:hover:white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700"
        >
            {{ blok.title }}
            <svg
                class="ml-1 w-5 h-5 lg:w-4 lg:h-4"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    fill-rule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                ></path>
            </svg>
        </button>

        <div
            :id="`mega-menu-${id}`"
            class="grid hidden absolute z-10 p-2 text-gray-900 bg-white w-full lg:w-auto lg:rounded-lg dark:text-white lg:col-span-2 dark:bg-gray-800"
        >
            <ul @click="closeMenu">
                <StoryblokComponent v-for="item in blok.links" :key="item._uid" :blok="item" />
            </ul>
        </div>
    </li>
</template>
